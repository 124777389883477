// import React from "react";
// import { Nav, Tab } from "react-bootstrap";
// import { Link } from "gatsby";
// import PageWrapper from "../components/PageWrapper";
// import ProfileSidebar from "../components/ProfileSidebar";

// import imgB1 from "../assets/image/l2/png/featured-job-logo-1.png";
// import imgB2 from "../assets/image/l1/png/feature-brand-1.png";
// import imgB3 from "../assets/image/svg/harvard.svg";
// import imgB4 from "../assets/image/svg/mit.svg";

// import imgT1 from "../assets/image/l3/png/team-member-1.png";
// import imgT2 from "../assets/image/l3/png/team-member-2.png";
// import imgT3 from "../assets/image/l3/png/team-member-3.png";
// import imgT4 from "../assets/image/l3/png/team-member-4.png";
// import imgT5 from "../assets/image/l3/png/team-member-5.png";

// import imgL from "../assets/image/svg/icon-loaction-pin-black.svg";

// const CandidateProfile = () => {
//   return (
//     <>
//       <PageWrapper headerConfig={{ button: "profile" }}>
//         <div className="bg-default-2 pt-22 pt-lg-25 pb-13 pb-xxl-32">
//           <div className="container">
//             {/* <!-- back Button --> */}
//             <div className="row justify-content-center">
//               <div className="col-12 dark-mode-texts">
//                 <div className="mb-9">
//                   <Link to="/#" className="d-flex align-items-center ml-4">
//                     {" "}
//                     <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
//                     <span className="text-uppercase font-size-3 font-weight-bold text-gray">
//                       Back
//                     </span>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             {/* <!-- back Button End --> */}
//             <div className="row">
//               {/* <!-- Left Sidebar Start --> */}
//               <div className="col-12 col-xxl-3 col-lg-4 col-md-5 mb-11 mb-lg-0">
//                 <ProfileSidebar />
//               </div>
//               {/* <!-- Left Sidebar End --> */}
//               {/* <!-- Middle Content --> */}
//               <div className="col-12 col-xxl-6 col-lg-8 col-md-7 order-2 order-xl-1">
//                 <Tab.Container id="left-tabs-example" defaultActiveKey="one">
//                   <div className="bg-white rounded-4 shadow-9">
//                     {/* <!-- Tab Section Start --> */}
//                     <Nav
//                       className="nav border-bottom border-mercury pl-12"
//                       role="tablist"
//                     >
//                       <li className="tab-menu-items nav-item pr-12">
//                         <Nav.Link
//                           eventKey="one"
//                           className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
//                         >
//                           Overview
//                         </Nav.Link>
//                       </li>
//                       <li className="tab-menu-items nav-item pr-12">
//                         <Nav.Link
//                           eventKey="two"
//                           className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0"
//                         >
//                           Contact
//                         </Nav.Link>
//                       </li>
//                     </Nav>
//                     {/* <!-- Tab Content --> */}
//                     <Tab.Content>
//                       <Tab.Pane eventKey="one">
//                         {/* <!-- Excerpt Start --> */}
//                         <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
//                           <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
//                             About
//                           </h4>
//                           <p className="font-size-4 mb-8">
//                             A talented professional with an academic background
//                             in IT and proven commercial development experience
//                             as C++ developer since 1999. Has a sound knowledge
//                             of the software development life cycle. Was involved
//                             in more than 140 software development outsourcing
//                             projects.
//                           </p>
//                           <p className="font-size-4 mb-8">
//                             Programming Languages: C/C++, .NET C++, Python,
//                             Bash, Shell, PERL, Regular expressions, Python,
//                             Active-script.
//                           </p>
//                         </div>
//                         {/* <!-- Excerpt End --> */}
//                         {/* <!-- Skills --> */}
//                         <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
//                           <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
//                             Skills
//                           </h4>
//                           <ul className="list-unstyled d-flex align-items-center flex-wrap">
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 Agile
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 Wireframing
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 Prototyping
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 Information
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 Waterfall Model
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 New Layout
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/#"
//                                 className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center"
//                               >
//                                 Browsing
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                         {/* <!-- Skills End --> */}
//                         {/* <!-- Card Section Start --> */}
//                         <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
//                           <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
//                             Work Exprerience
//                           </h4>
//                           {/* <!-- Single Card --> */}
//                           <div className="w-100">
//                             <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
//                               <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
//                                 <img src={imgB1} alt="" />
//                               </div>
//                               <div className="w-100 mt-n2">
//                                 <h3 className="mb-0">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-6 text-black-2 font-weight-semibold"
//                                   >
//                                     Lead Product Designer
//                                   </Link>
//                                 </h3>
//                                 <Link
//                                   to="/#"
//                                   className="font-size-4 text-default-color line-height-2"
//                                 >
//                                   Airabnb
//                                 </Link>
//                                 <div className="d-flex align-items-center justify-content-md-between flex-wrap">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-4 text-gray mr-5"
//                                   >
//                                     Jun 2017 - April 2020- 3 years
//                                   </Link>
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray"
//                                   >
//                                     <span
//                                       className="mr-4"
//                                       css={`
//                                         margin-top: -2px;
//                                       `}
//                                     >
//                                       <img src={imgL} alt="" />
//                                     </span>
//                                     New York, USA
//                                   </Link>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           {/* <!-- Single Card End --> */}
//                           {/* <!-- Single Card --> */}
//                           <div className="w-100">
//                             <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
//                               <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
//                                 <img src={imgB2} alt="" />
//                               </div>
//                               <div className="w-100 mt-n2">
//                                 <h3 className="mb-0">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-6 text-black-2 font-weight-semibold"
//                                   >
//                                     Senior UI/UX Designer
//                                   </Link>
//                                 </h3>
//                                 <Link
//                                   to="/#"
//                                   className="font-size-4 text-default-color line-height-2"
//                                 >
//                                   Google Inc
//                                 </Link>
//                                 <div className="d-flex align-items-center justify-content-md-between flex-wrap">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray mr-5"
//                                   >
//                                     Jun 2017 - April 2020- 3 years
//                                   </Link>
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray"
//                                   >
//                                     <span
//                                       className="mr-4"
//                                       css={`
//                                         margin-top: -2px;
//                                       `}
//                                     >
//                                       <img src={imgL} alt="" />
//                                     </span>
//                                     New York, USA
//                                   </Link>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           {/* <!-- Single Card End --> */}
//                         </div>
//                         {/* <!-- Card Section End --> */}
//                         {/* <!-- Card Section Start --> */}
//                         <div className="border-top p-5 pl-xs-12 pt-7 pb-5">
//                           <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
//                             Education
//                           </h4>
//                           {/* <!-- Single Card --> */}
//                           <div className="w-100">
//                             <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
//                               <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
//                                 <img src={imgB3} alt="" />
//                               </div>
//                               <div className="w-100 mt-n2">
//                                 <h3 className="mb-0">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-6 text-black-2"
//                                   >
//                                     Masters in Art Design
//                                   </Link>
//                                 </h3>
//                                 <Link
//                                   to="/#"
//                                   className="font-size-4 text-default-color line-height-2"
//                                 >
//                                   Harvard University
//                                 </Link>
//                                 <div className="d-flex align-items-center justify-content-md-between flex-wrap">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray mr-5"
//                                   >
//                                     Jun 2017 - April 2020- 3 years
//                                   </Link>
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray"
//                                   >
//                                     <span
//                                       className="mr-4"
//                                       css={`
//                                         margin-top: -2px;
//                                       `}
//                                     >
//                                       <img src={imgL} alt="" />
//                                     </span>
//                                     Brylin, USA
//                                   </Link>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           {/* <!-- Single Card End --> */}
//                           {/* <!-- Single Card --> */}
//                           <div className="w-100">
//                             <div className="d-flex align-items-center pr-11 mb-9 flex-wrap flex-sm-nowrap">
//                               <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
//                                 <img src={imgB4} alt="" />
//                               </div>
//                               <div className="w-100 mt-n2">
//                                 <h3 className="mb-0">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-6 text-black-2"
//                                   >
//                                     Bachelor in Software Engineering{" "}
//                                   </Link>
//                                 </h3>
//                                 <Link
//                                   to="/#"
//                                   className="font-size-4 text-default-color line-height-2"
//                                 >
//                                   Manipal Institute of Technology
//                                 </Link>
//                                 <div className="d-flex align-items-center justify-content-md-between flex-wrap">
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray mr-5"
//                                   >
//                                     Fed 2012 - April 2016 - 4 years
//                                   </Link>
//                                   <Link
//                                     to="/#"
//                                     className="font-size-3 text-gray"
//                                   >
//                                     <span
//                                       className="mr-4"
//                                       css={`
//                                         margin-top: -2px;
//                                       `}
//                                     >
//                                       <img src={imgL} alt="" />
//                                     </span>
//                                     New York, USA
//                                   </Link>
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                           {/* <!-- Single Card End --> */}
//                         </div>
//                         {/* <!-- Card Section End --> */}
//                       </Tab.Pane>
//                       <Tab.Pane eventKey="two">
//                         {/* <!-- Excerpt Start --> */}
//                         <div className="pr-xl-11 p-5 pl-xs-12 pt-9 pb-11">
//                           <form action="/">
//                             <div className="row">
//                               <div className="col-12 mb-7">
//                                 <label
//                                   htmlFor="name3"
//                                   className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
//                                 >
//                                   Your Name
//                                 </label>
//                                 <input
//                                   id="name3"
//                                   type="text"
//                                   className="form-control"
//                                   placeholder="Jhon Doe"
//                                 />
//                               </div>
//                               <div className="col-lg-6 mb-7">
//                                 <label
//                                   htmlFor="email3"
//                                   className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
//                                 >
//                                   E-mail
//                                 </label>
//                                 <input
//                                   id="email3"
//                                   type="email"
//                                   className="form-control"
//                                   placeholder="example@gmail.com"
//                                 />
//                               </div>
//                               <div className="col-lg-6 mb-7">
//                                 <label
//                                   htmlFor="subject3"
//                                   className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
//                                 >
//                                   Subject
//                                 </label>
//                                 <input
//                                   id="subject3"
//                                   type="text"
//                                   className="form-control"
//                                   placeholder="Special contract"
//                                 />
//                               </div>
//                               <div className="col-lg-12 mb-7">
//                                 <label
//                                   htmlFor="message3"
//                                   className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset"
//                                 >
//                                   Message
//                                 </label>
//                                 <textarea
//                                   name="message"
//                                   id="message3"
//                                   placeholder="Type your message"
//                                   className="form-control h-px-144"
//                                 ></textarea>
//                               </div>
//                               <div className="col-lg-12 pt-4">
//                                 <button className="btn btn-primary text-uppercase w-100 h-px-48">
//                                   Send Now
//                                 </button>
//                               </div>
//                             </div>
//                           </form>
//                         </div>
//                         {/* <!-- Excerpt End --> */}
//                       </Tab.Pane>
//                     </Tab.Content>
//                     {/* <!-- Tab Content End --> */}
//                     {/* <!-- Tab Section End --> */}
//                   </div>
//                 </Tab.Container>
//               </div>
//               {/* <!-- Middle Content --> */}
//               {/* <!-- Right Sidebar Start --> */}
//               <div className="col-12 col-xxl-3 col-md-4 offset-xxl-0 offset-lg-4 offset-md-5 order-3 order-xl-2 mt-xxl-0 mt-md-12">
//                 <div className="pl-lg-5">
//                   <h4 className="font-size-6 font-weight-semibold mb-0">
//                     Other experts
//                   </h4>
//                   <ul className="list-unstyled">
//                     {/* <!-- Single List --> */}
//                     <li className="border-bottom">
//                       <Link
//                         to="/#"
//                         className="media align-items-center py-9 flex-wrap"
//                       >
//                         <div className="mr-7">
//                           <img
//                             className="square-72 rounded-3"
//                             src={imgT1}
//                             alt=""
//                           />
//                         </div>
//                         <div className="">
//                           <h4 className="mb-0 font-size-5 font-weight-semibold">
//                             David Herison
//                           </h4>
//                           <p className="mb-0 font-size-3 heading-default-color">
//                             UX/UI Designer
//                           </p>
//                           <span className="font-size-3 text-smoke">
//                             <img className="mr-2" src={imgL} alt="" />
//                             New York, USA
//                           </span>
//                         </div>
//                       </Link>
//                     </li>
//                     {/* <!-- Single List End --> */}
//                     {/* <!-- Single List --> */}
//                     <li className="border-bottom">
//                       <Link
//                         to="/#"
//                         className="media align-items-center py-9 flex-wrap"
//                       >
//                         <div className="mr-7">
//                           <img
//                             className="square-72 rounded-3"
//                             src={imgT2}
//                             alt=""
//                           />
//                         </div>
//                         <div className="">
//                           <h4 className="mb-0 font-size-5 font-weight-semibold">
//                             Mark Zanitos
//                           </h4>
//                           <p className="mb-0 font-size-3 heading-default-color">
//                             Lead Product Designer
//                           </p>
//                           <span className="font-size-3 text-smoke">
//                             <img className="mr-2" src={imgL} alt="" />
//                             New York, USA
//                           </span>
//                         </div>
//                       </Link>
//                     </li>
//                     {/* <!-- Single List End --> */}
//                     {/* <!-- Single List --> */}
//                     <li className="border-bottom">
//                       <Link
//                         to="/#"
//                         className="media align-items-center py-9 flex-wrap"
//                       >
//                         <div className="mr-7">
//                           <img
//                             className="square-72 rounded-3"
//                             src={imgT3}
//                             alt=""
//                           />
//                         </div>
//                         <div className="">
//                           <h4 className="mb-0 font-size-5 font-weight-semibold">
//                             Anna Frankin
//                           </h4>
//                           <p className="mb-0 font-size-3 heading-default-color">
//                             Visual Designer
//                           </p>
//                           <span className="font-size-3 text-smoke">
//                             <img className="mr-2" src={imgL} alt="" />
//                             New York, USA
//                           </span>
//                         </div>
//                       </Link>
//                     </li>
//                     {/* <!-- Single List End --> */}
//                     {/* <!-- Single List --> */}
//                     <li className="border-bottom">
//                       <Link
//                         to="/#"
//                         className="media align-items-center py-9 flex-wrap"
//                       >
//                         <div className="mr-7">
//                           <img
//                             className="square-72 rounded-3"
//                             src={imgT4}
//                             alt=""
//                           />
//                         </div>
//                         <div className="">
//                           <h4 className="mb-0 font-size-5 font-weight-semibold">
//                             Jhony Vino
//                           </h4>
//                           <p className="mb-0 font-size-3 heading-default-color">
//                             Creative Director
//                           </p>
//                           <span className="font-size-3 text-smoke">
//                             <img className="mr-2" src={imgL} alt="" />
//                             New York, USA
//                           </span>
//                         </div>
//                       </Link>
//                     </li>
//                     {/* <!-- Single List End --> */}
//                     {/* <!-- Single List --> */}
//                     <li className="">
//                       <Link
//                         to="/#"
//                         className="media align-items-center py-9 flex-wrap"
//                       >
//                         <div className="mr-7">
//                           <img
//                             className="square-72 rounded-3"
//                             src={imgT5}
//                             alt=""
//                           />
//                         </div>
//                         <div className="">
//                           <h4 className="mb-0 font-size-5 font-weight-semibold">
//                             Aniasta Hemberg
//                           </h4>
//                           <p className="mb-0 font-size-3 heading-default-color">
//                             Creative Director
//                           </p>
//                           <span className="font-size-3 text-smoke">
//                             <img className="mr-2" src={imgL} alt="" />
//                             New York, USA
//                           </span>
//                         </div>
//                       </Link>
//                     </li>
//                     {/* <!-- Single List End --> */}
//                   </ul>
//                 </div>
//               </div>
//               {/* <!-- Right Sidebar End --> */}
//             </div>
//           </div>
//         </div>
//       </PageWrapper>
//     </>
//   );
// };
// export default CandidateProfile;


import React, { useContext, useEffect, useRef, useState } from "react";
import { Nav, Tab, Collapse, Button } from "react-bootstrap";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import ProfileSidebar from "../components/ProfileSidebar";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";

import imgB1 from "../assets/image/l2/png/featured-job-logo-1.png";
import imgB2 from "../assets/image/l1/png/feature-brand-1.png";
import imgB3 from "../assets/image/svg/harvard.svg";
import imgB4 from "../assets/image/svg/mit.svg";

import imgT1 from "../assets/image/l3/png/team-member-1.png";
import imgT2 from "../assets/image/l3/png/team-member-2.png";
import imgT3 from "../assets/image/l3/png/team-member-3.png";
import imgT4 from "../assets/image/l3/png/team-member-4.png";
import imgT5 from "../assets/image/l3/png/team-member-5.png";

import imgL from "../assets/image/svg/icon-loaction-pin-black.svg";
import { backgroundColor } from "styled-system";

const ProfileDetailsView = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const userId = params.get("id");
  const isMountedRef = useRef(false);

  const [user, setUser] = useState('');
  const gContext = useContext(GlobalContext);
  const { user: loggedUser } = useContext(GlobalContext);
  const [loggedUserId, setLoggedUserId] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [profiles, setProfiles] = useState([]);

  const imageBaseUrl = `${network.serverip}/images/`;
  const [collapsible, setCollapsible] = useState('');

  const documentIconMap = {
    resume: 'fas fa-file-alt',
    aadhaar: 'fas fa-id-card',
    other_address_proof: 'fas fa-file',
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const fetchProfiles = async (search, count) => {
    try {

      if (!loggedUserId) {
        console.log('No user found for profiles fetching');
        return
      }

      console.log('This is the userId:', userId);
    
      const response = await axios.post(`${network.serverip}/api/recommended-profilelist-forweb`, { search, count, userId: loggedUserId });
      if (response.data.success && isMountedRef.current) {
        let allProfiles = response.data.data;
        setProfiles(allProfiles.filter(profile => profile?.id !== Number(userId)));
        console.log('This is the fetched recommended profiles:', response.data.data);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setProfiles([]);
        }
        console.error('Failed to fetch profiles');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setProfiles([]);
      }
      console.error('Error fetching profiles:', error);
    }
  };

  const handleFileDownload = async (fileName, name) => {
    try {
      // Define the document URL
      const documentUrl = `${network.serverip}/storage/${fileName}`;
      console.log('Downloading from URL:', documentUrl);

      // Fetch the document from the server using Axios
      const response = await axios.get(documentUrl, {
        responseType: 'blob' // Important: This ensures that the response is treated as a Blob
      });

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error('Failed to download document');
      }

      // Convert the response data into a Blob URL
      const fileUrl = window.URL.createObjectURL(response.data);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = name; // Set the file name

      // Programmatically trigger a click event to download the file
      document.body.appendChild(a);
      a.click();

      // Cleanup: Remove the anchor and revoke the Blob URL
      a.remove();
      window.URL.revokeObjectURL(fileUrl);

      console.log(`Download complete: ${name}`);

      // Optionally, show a notification after the download completes
      alert(`The file "${name}" has been downloaded successfully.`);

    } catch (error) {
      console.error('Error downloading the document:', error);

      // Optional: Show a notification in case of failure
      alert(`Failed to download the file "${name}". Please try again.`);
    }
  };

  const fetchUserDetails = async () => {
    try {

      const response = await axios.post(`${network.serverip}/api/profile-details-for-web`, {
        userId: userId,
        loggedUserId: loggedUserId,
      });

      if (response.data.success && isMountedRef.current) {
        setUser(response.data.userData);
        console.log('This is the fetched user details:', response.data.userData);
      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUser('');
        }
        console.error('Failed to fetch user details');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUser('');
      }
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    if (loggedUser && loggedUser.id) {
      setLoggedUserId(loggedUser.id)
    }
  }, [loggedUser]);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the candidate id');
      return;
    }

    fetchUserDetails();
    fetchProfiles('', 10);

  }, [userId, loggedUserId]);


  const styles = ProfileDetailsViewStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/dashboard-main');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xxl-3 col-lg-4 col-md-5 mb-11 mb-lg-0">
                <ProfileSidebar user={user} />
              </div>
              <div className="col-12 col-xxl-6 col-lg-8 col-md-7 order-2 order-xl-1">
                <div className="bg-white rounded-4 shadow-9">
                  <div className="pr-xl-0 pr-xxl-14 p-5 px-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                      About
                    </h4>
                    <p className="font-size-4 mb-8" style={{ textAlign: 'justify' }}>
                      {user?.profile?.about_me &&
                        <span>
                          {(showMore || user.profile.about_me.length <= 150)
                            ? `${user.profile.about_me} `
                            : `${user.profile.about_me.slice(0, 150)}... `}
                          {user.profile.about_me.length > 150 && (
                            <button onClick={() => setShowMore(!showMore)} style={styles.showMoreButton}>
                              {showMore ? 'Show less' : 'Show more'}
                            </button>
                          )}
                        </span>
                      }
                    </p>
                  </div>

                  <div className="border-top pr-xl-0 pr-xxl-14 p-5 pl-xs-12 pt-7 pb-5">
                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                      Skills
                    </h4>
                    <ul className="list-unstyled d-flex align-items-center flex-wrap">
                      {user && user.user_skill && user.user_skill.length > 0 ? (
                        user.user_skill.map((skill, index) => (
                          <li key={index}>
                            <p className="bg-polar text-black-2 mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center">
                              {skill?.skill?.title || 'N/A'}
                            </p>
                          </li>
                        ))
                      ) : (
                        <li>No skills available</li>
                      )}
                    </ul>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'documents' ? '' : 'documents')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Documents
                      </h4>
                      <i
                        className={`fas ${collapsible === 'documents' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'documents'}>
                      <div id="documents-collapse">
                        {user && user.documents && user.documents.length > 0 ? (
                          user.documents.map((doc, index) => {

                            return (
                              <div className="w-100 border-top" key={index}>
                                <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                  <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                    <i
                                      className={`${documentIconMap[doc.type.toLowerCase()] || 'fas fa-file'} font-size-4 text-success`}
                                    ></i>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-md-start flex-wrap">
                                      <p className="mb-0 font-size-4 text-black-2 font-weight-semibold text-upper">
                                        {handleWordChange(doc?.type || '')}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-md-end flex-wrap">
                                      <button
                                        style={styles.downloadButton}
                                        className="d-flex"
                                        onClick={() => handleFileDownload(`documents/${doc.file_name}`, doc.document_name)}
                                      >
                                        <p className="mb-0 font-size-4 text-dodger font-weight-semibold text-upper">
                                          {doc?.document_name}
                                        </p>
                                        <i className="fas fa-download font-size-4 text-dodger ml-2"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No documents available.</p>
                        )}
                      </div>
                    </Collapse>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'certificates' ? '' : 'certificates')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Certificates
                      </h4>
                      <i
                        className={`fas ${collapsible === 'certificates' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'certificates'}>
                      <div id="certificates-collapse">
                        {user && user.certificates && user.certificates.length > 0 ? (
                          user.certificates.map((cer, index) => {

                            return (
                              <div className="w-100 border-top" key={index}>
                                <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                  <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                    <i
                                      className="fas fa-id-card font-size-4 text-success"
                                    ></i>
                                  </div>
                                  <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-md-start flex-wrap">
                                      <p className="mb-0 font-size-4 text-black-2 font-weight-semibold text-upper">
                                        {cer?.name}
                                      </p>
                                    </div>
                                    <p className="font-size-4 text-default-color">
                                      {`Register Number: ${cer?.register_number}`}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-md-end flex-wrap">
                                      <button
                                        style={styles.downloadButton}
                                        className="d-flex"
                                        onClick={() => handleFileDownload(cer.file_name, cer.document_name)}
                                      >
                                        <p className="mb-0 font-size-4 text-dodger font-weight-semibold text-upper">
                                          {cer?.document_name}
                                        </p>
                                        <i className="fas fa-download font-size-4 text-dodger ml-2"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No certificates available.</p>
                        )}
                      </div>
                    </Collapse>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'qualification' ? '' : 'qualification')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Qualification
                      </h4>
                      <i
                        className={`fas ${collapsible === 'qualification' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'qualification'}>
                      <div id="qualification-collapse">
                        {user && user.qualification && user.qualification.length > 0 ? (
                          user.qualification.map((qual, index) => {
                            const fromDate = qual.from_date ? new Date(qual.from_date) : null;
                            const toDate = qual.to_date ? new Date(qual.to_date) : null;

                            const formattedFromDate = fromDate
                              ? fromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                              : 'Start date not available';

                            const formattedToDate = toDate
                              ? toDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                              : 'End date not available';

                            return (
                              <div className="w-100 border-top" key={index}>
                                <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                  <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                    <i className="fas fa-graduation-cap font-size-4 text-success"></i>
                                  </div>
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {qual?.qualification_name?.title}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      {qual?.field_of_study?.title} {`, ${qual?.institution}`}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                      <span className="font-size-4 text-gray mr-5">
                                        {`${formattedFromDate} - ${formattedToDate}`}
                                      </span>
                                      <span className="font-size-3 text-gray">
                                        <i className="fas fa-map-marker-alt mr-4"></i>
                                        {qual?.location}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No qualification available.</p>
                        )}
                      </div>
                    </Collapse>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'experience' ? '' : 'experience')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Experience
                      </h4>
                      <i
                        className={`fas ${collapsible === 'experience' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'experience'}>
                      <div id="experience-collapse">
                        {user && user.experience && user.experience.length > 0 ? (
                          user.experience.map((exp, index) => {
                            const fromDate = exp.from_date ? new Date(exp.from_date) : null;
                            const toDate = exp.currently_work_here
                              ? 'Present'
                              : exp.to_date
                                ? new Date(exp.to_date)
                                : null;

                            const formattedFromDate = fromDate
                              ? fromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                              : 'Start date not available';
                            const formattedToDate =
                              toDate === 'Present'
                                ? 'Present'
                                : toDate
                                  ? toDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                                  : 'End date not available';

                            const monthsOfExperience =
                              fromDate && toDate !== 'Present' && toDate
                                ? Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24 * 30))
                                : fromDate && toDate === 'Present'
                                  ? Math.floor((new Date() - fromDate) / (1000 * 60 * 60 * 24 * 30))
                                  : null;

                            const yearsOfExperience = monthsOfExperience ? (monthsOfExperience / 12).toFixed(1) : null;

                            const experienceInYears = yearsOfExperience
                              ? `${yearsOfExperience} year(s)`
                              : 'Experience not available';

                            return (
                              <div className="w-100 border-top" key={index}>
                                <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                  <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                    <i className="fas fa-briefcase font-size-4 text-success"></i>
                                  </div>
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {exp?.job_title}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      {exp?.institution_name}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                      <span className="font-size-4 text-gray mr-5">
                                        {`${formattedFromDate} - ${formattedToDate} (${experienceInYears})`}
                                      </span>
                                      <span className="font-size-3 text-gray">
                                        <i className="fas fa-map-marker-alt mr-4"></i>
                                        {exp?.location}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No experience available.</p>
                        )}
                      </div>
                    </Collapse>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'procedure' ? '' : 'procedure')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Procedure
                      </h4>
                      <i
                        className={`fas ${collapsible === 'procedure' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'procedure'}>
                      <div id="procedure-collapse">
                        {user && user.procedure && user.procedure.length > 0 ? (
                          user.procedure.map((proc, index) => {

                            return (
                              <div className="w-100 border-top" key={index}>
                                <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                  <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                    <i className="fas fa-hospital-alt font-size-4 text-success"></i>
                                  </div>
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {proc?.title}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      {`Number of times performed: ${proc?.procedure_count}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>No procedure available.</p>
                        )}
                      </div>
                    </Collapse>
                  </div>

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => setCollapsible(collapsible === 'time_availability' ? '' : 'time_availability')}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Time Availability
                      </h4>
                      <i
                        className={`fas ${collapsible === 'time_availability' ? 'fa-chevron-up' : 'fa-chevron-down'} text-success font-size-6`}
                      ></i>
                    </button>

                    {/* Collapsible section */}
                    <Collapse in={collapsible === 'time_availability'}>
                      <div id="time_availability-collapse">
                        {user && user.time_availability && user.time_availability.length > 0 ? (
                          user.time_availability.map((time, index) => {

                            const date = time?.date ? new Date(time.date) : null;

                            return (
                              <div className="w-100 border-top" key={index}>
                                <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                  <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                    <i className="fas fa-calendar-alt font-size-4 text-success"></i>
                                  </div>
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {date ? `Date: ${date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}` : 'Date not available'}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      <i className="fas fa-clock font-size-4 text-default-color mr-2"></i>
                                      {`Shift: ${time?.shift}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>There is no available time mentioned.</p>
                        )}
                      </div>
                    </Collapse>
                  </div>
                </div>

              </div>
              <div className="col-12 col-xxl-3 col-md-4 offset-xxl-0 offset-lg-4 offset-md-5 order-3 order-xl-2 mt-xxl-0 mt-md-12">
                <div className="pl-lg-5">
                  <h4 className="font-size-6 font-weight-semibold mb-0">
                    Other profiles
                  </h4>
                  <ul className="list-unstyled">
                    {/* <!-- Single List --> */}

                    {profiles.length > 0 && profiles.map((profile, index) => (
                      <li className="border-bottom" key={index}>
                        <Link
                          to={`/profile-details-view/?id=${profile.id}`}
                          className="media align-items-center py-9 flex-wrap"
                        >
                          <div className="mr-7">
                            <img
                              className="square-72 rounded-3"
                              src={profile?.image ? imageBaseUrl + profile.image : imageBaseUrl + "profile_default_photo.png"}
                              alt=""
                            />
                          </div>
                          <div className="">
                            <h4 className="mb-0 font-size-5 font-weight-semibold">
                              {profile?.name}
                            </h4>
                            <p className="mb-0 font-size-3 heading-default-color">
                              {profile?.role}
                            </p>
                            <span className="font-size-3 text-smoke">
                              <img className="mr-2" src={imgL} alt="" />
                              {profile?.city}
                              {profile?.state ? `, ${profile?.state}` : ''}
                            </span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default ProfileDetailsView;

function ProfileDetailsViewStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    showMoreButton: {
      display: 'inline-block',
      background: 'none',
      border: 'none',
      color: 'blue',
      cursor: 'pointer',
      textDecoration: 'underline',
      outline: 'none',
    },
  };

  return styles;
}

